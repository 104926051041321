<template>

  <div class="leisure_button_group_sub">
    <router-link tag="button" class="bw4"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_DONGHANG_POWERBALL_RANDOM}"
                 :to="{path:'/front/minigame/donghangpowerballrandom', query: {t: new Date().getTime()}}">동행 파워볼(랜덤)
    </router-link>

    <router-link tag="button" class="bw4"
                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_DONGHANG_POWERSADALI}"
                 :to="{path:'/front/minigame/donghangpowersadali', query: {t: new Date().getTime()}}">동행 파워사다리
    </router-link>

<!--    <router-link tag="button" class="bw4"-->
<!--                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_SPEEDKINO}"-->
<!--                 :to="{path:'/front/minigame/speedkino', query: {t: new Date().getTime()}}"> 스피드키노-->
<!--    </router-link>-->

<!--    <router-link tag="button" class="bw4"-->
<!--                 :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_KINOSADALI}"-->
<!--                 :to="{path:'/front/minigame/kinosadali', query: {t: new Date().getTime()}}">키노사다리-->
<!--    </router-link>-->

  </div>


</template>
<script>
import leisureConst from "@/common/leisureConst";

export default {
  name: "LeisureCompDonghangGameLinks",
  props: {
    comp: {
      type: Number,
      default() {
        return -1
      }
    },
  },
  data() {
    return {
      leisureConst: leisureConst,
    }
  }
}
</script>

<style scoped>

</style>